import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 2
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FOUR"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Why is the one-to-five check in effective?"}
        answers={[
          "Because it can allow for non-verbal answers",
          "Because numbers are better than words",
          "So that we can get through faster than listening to a description",
          "Because it only applies for sports",
        ]}
        correctAnswerNumber={0}
        answerDetail={
          "Sometimes girls may not have the words to describe how they are feeling so a non-verbal response provide a more accurate and specific way of stating this. "
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 4, 4)
